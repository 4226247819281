import React from 'react';
import {Box, Typography, Grid, useMediaQuery, useTheme} from '@mui/material';
import {color} from '../../theme';
import {DescriptionBox} from './descriptionBox';

const SignupBanner = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const steps = [
        {
            title: '1. Lag en bruker',
            description: [
                'Lag en bruker i Groweb slik at vi kan kontakte deg.',
                'Etter at din bruker er laget starter bestillingsprosessen og du blir veiledet til et bestillingsskjema.',
            ],
        },
        {
            title: '2. Fyll ut skjema',
            description: [
                'Fyll ut skjemaet der du velger design, farger og fonter.',
                'Du kan laste opp bilder, logo og forklare hva bedriften din driver med.',
            ],
        },
        {
            title: '3. Vi lager nettsiden',
            description: [
                'Vi tar informasjonen du har gitt oss i bestillingen til å lage nettsiden din.',
                'Ved hjelp av våre erfarne utviklere og AI sørger vi for å lage en god nettside for deg.',
            ],
        },
        {
            title: '4. Bli synlig på nett',
            description: [
                'Vi setter opp et digitalt møte der du får en full gjennomgang av nettsiden og nettsidebyggeren, slik at du enkelt kan gjøre endringer selv når det passer deg.',
            ],
        },
    ];

    return (
        <Box
            sx={{
                width: '100%',
                padding: isSmallScreen ? '2em' : '4em 2em 2em 2em',
                backgroundColor: '#21245a',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                color: 'white',
            }}
        >
            <Typography variant="h2"
                        sx={{fontWeight: 'bold', mb: 2, color: color.white.white100, fontFamily: 'Fraunces'}}>
                Ferdig nettside levert raskt og billig!
            </Typography>
            <Typography variant="h4" sx={{mb: 4, color: color.white.white100, fontFamily: 'Fraunces'}}>Slik fungerer
                det</Typography>
            <Grid container columnSpacing={4} rowSpacing={8} maxWidth="md">
                {steps.map((step, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Box
                            sx={{
                                background: 'white',
                                padding: '20px',
                                borderRadius: '8px',
                                height: '100%',
                            }}
                        >
                            <Typography variant="h4" sx={{
                                fontWeight: 'bold',
                                mb: 1,
                                fontFamily: 'Fraunces',
                                textAlign: 'left',
                                padding: '1rem 1.0rem'
                            }}>
                                {step.title}
                            </Typography>
                            {step.description.map((text, i) => (
                                <DescriptionBox key={i} text={text}/>
                            ))}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SignupBanner;
