import Box from "@mui/system/Box";
import { color } from "../../theme";
import {Typography} from "@mui/material";

export const DescriptionBox = ({ text }) => {
    return (
        <Box
            component="li"
            sx={{
                padding: '0.5rem 1.2rem',
                fontSize: { xs: '0.90rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' },
                textAlign: 'left',
                lineHeight: '1.5',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
                transition: 'all 0.3s ease',
            }}
        >
            <Typography variant="body" sx={{
                                mb: 1,
                                fontFamily: 'Poppins',
                                textAlign: 'left',
            }}>{text}</Typography>
        </Box>
    );
};